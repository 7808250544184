/*
 * @Author: Vir
 * @Date: 2022-04-08 16:05:10
 * @Last Modified by: Vir
 * @Last Modified time: 2022-04-08 16:06:01
 */
import React, { FC } from 'react';

const SearchBar: FC = () => {
  return <div>SearchBox</div>;
};

export default SearchBar;
