/*
 * @Author: Vir
 * @Date: 2021-10-16 22:24:38
 * @Last Modified by: Vir
 * @Last Modified time: 2021-10-26 15:14:27
 */

import C1 from './Clock1';
import C2 from './Clock2';
import C3 from './Clock3';

export const Clock1 = C1;
export const Clock2 = C2;
export const Clock3 = C3;
